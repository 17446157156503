import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './QuantitySelector.module.scss'
import { IQuantitySelector } from '../../../interfaces/QuantitySelector'
import stylesButton from "./QuantitySelector.module.scss";

const QuantitySelector: FC<IQuantitySelector> = ({
  subtractQuantity,
  addQuantity,
  quantity,
  reconciliation,
  product_sku,
  productPrice,
  stylesBorder
}) => {
  const { t }: { t: any } = useTranslation()

  return !quantity ? (
    <button
      onClick={(e) => addQuantity(e)}
      className={`${styles.button}`}
      id={product_sku}
      data-cost={productPrice}
    >
      {t('add-to-cart')}
    </button>
  ) : (
    <div
      className={`${stylesBorder} ${
          reconciliation ? stylesButton.quantitySelectorDisabled : ''
      }`}
    >
      <div
        className={styles.quantitySelectorControls}
        tabIndex={0}
        // for desktop width
      >
        <button
          className={`${styles.quantitySelectorMinus}`}
          onClick={subtractQuantity}
          disabled={reconciliation}
          id={`QuantitySelector_quantitySelectorMinus_${product_sku}`}
        >
          -
        </button>
        <div
          className={styles.quantitySelectorDisplay}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
        >
          {quantity}
        </div>
        <button
          className={styles.quantitySelectorPlus}
          onClick={addQuantity}
          disabled={reconciliation}
          id={`QuantitySelector_quantitySelectorPlus_${product_sku}`}
          data-cost={productPrice}
        >
          +
        </button>
      </div>
    </div>
  )
}

export default QuantitySelector
