import React from 'react'
import { useTranslation } from 'next-i18next'

import styles from './CashbackInfo.module.scss'

type MyProps = {
  cashbackAmount: number
}

const CashbackInfo = ({ cashbackAmount }: MyProps) => {
  const { t }: { t: any } = useTranslation()
  return (
    <>
      {cashbackAmount ? (
        <p className={styles.cashBackText}>
          {t('you-will-earn')} <strong>${cashbackAmount}</strong>{' '}
          {t('cashback-for-this-order')}
        </p>
      ) : null}
      {!cashbackAmount ? (
        <p className={styles.cashBackText}>
          {t('order-for-more-than')} <strong>$20</strong> {t('and-get')}&nbsp;
          <strong>5%</strong> {t('cashback')}
        </p>
      ) : null}
    </>
  )
}

export default CashbackInfo
