import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import styles from './PopupSingIn.module.scss'
import { getCookie } from 'cookies-next'
import { useSubmitForms } from '../../../../customHooks/useSubmitForms'
import { FormsInputField, Preloader } from '../../../common'
import { signInFormData1 } from '../../../Cart/CartSection/staticData'
import { useCustomerDataContext } from '../../../../contexts/CustomerDataContext'
import { getUserInfo, getTaxCost } from '../../../../utilities/getUserInfo'
import { getCustomerData } from '../../../../utilities/getCustomerData'
import {
  annualmembershipSku,
  customerIdentityCookieName,
  trialMembershipSku,
} from '../../../../constants/constants'
import { customerMembership } from '../../../../utilities/customerMembership'
import { ProductDetails } from '../../../../interfaces/CartContent'
import { LineItem, OrderClass } from '../../../../interfaces/OrderTypes'
import { parseJson } from '../../../../utilities/jsonUtils'
import { CookieValueTypes } from 'cookies-next/lib/types'
import {
  getDiscountPrice,
  getProductsWithNewPrice,
} from '../../../../utilities/getDiscountPrice'
//import {signInFormData} from "../../../Account/Signin/staticData";

type MyProps = {
  closePopup: (closePopup: boolean) => void
  isOpenResetYourPassword: any
  userEmail: string
  setCoefficientDiscountState: (value: number) => void
  updateCartContent: (value: ProductDetails[]) => void
  discountCode: string | null
}

const PopupSingIn: FC<MyProps> = ({
  closePopup,
  isOpenResetYourPassword,
  userEmail = '',
  setCoefficientDiscountState,
  updateCartContent,
  discountCode,
}) => {
  const { t }: { t: any } = useTranslation()
  const { setDataContext, dataContext } = useCustomerDataContext()
  const { signInSubmit } = useSubmitForms()
  const _identity = getCookie(customerIdentityCookieName)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: { login: userEmail, password: '' },
  })

  const [isFullFilled, setIsFullFilled] = useState<null | boolean>(null)
  const [isFetching, setIsFetching] = useState(false)

  const customerContext = useCallback(async () => {
    const coefficientDiscount = await getDiscountPrice()

    setCoefficientDiscountState(coefficientDiscount)

    const parsedToken = _identity
      ? parseJson<CookieValueTypes>(_identity as string)
      : _identity
    const customerData = await getCustomerData({
      [customerIdentityCookieName]: _identity,
    })

    const includedMemberShip = await customerMembership(
      null,
      dataContext.setCartContent,
      true
    )
    const userInfo = await getUserInfo(parsedToken)
    const draftOrder = await getTaxCost(
      userInfo.userData,
      0,
      null,
      undefined,
      discountCode
    )

    let withoutOldMembership = dataContext.productsList.filter(
      (el: ProductDetails) =>
        el.sku !== trialMembershipSku && el.sku !== annualmembershipSku
    )

    withoutOldMembership = [
      ...withoutOldMembership.map((item: ProductDetails) => ({
        ...item,
        price: draftOrder?.line_items.find(
          (draftItem: LineItem) => draftItem.variant_id === +item.variantId
        ).price,
      })),
    ]

    withoutOldMembership = (await getProductsWithNewPrice(
      withoutOldMembership,
      coefficientDiscount
    )) as ProductDetails[]

    if (draftOrder?.applied_discount?.value) {
      withoutOldMembership = (await getProductsWithNewPrice(
        withoutOldMembership,
        1 - +draftOrder?.applied_discount?.value / 100
      )) as ProductDetails[]
    }

    setDataContext((_prev: any) => ({
      ..._prev,
      customerData,
      userInfo: userInfo.userData,
      userAddress: userInfo.userAddress,
      taxPrice: +draftOrder?.total_tax,
      distancePrice: {
        ..._prev?.distancePrice,
        cost:
          (
            draftOrder?.total_shipping_price_set?.presentment_money?.amount *
            100
          ).toFixed(2) || 0,
      },
      maxCashbackAmount: draftOrder
        ? draftOrder.note_attributes?.find(
            (item: OrderClass) => item.name === 'maxCashbackForSpending'
          )?.value
        : '0',
      productsList: [...withoutOldMembership, ...includedMemberShip],
    }))

    updateCartContent(withoutOldMembership)

    dataContext.resetForm({ ...userInfo.userData })
    setIsFetching(false)
    closePopup(false)
  }, [_identity])

  useEffect(() => {
    if (isFullFilled) {
      customerContext()
    } else if (isFullFilled !== null) {
      setIsFetching(false)
      setIsFullFilled(null)
    }
  }, [customerContext, isFullFilled])

  const onSubmit = async (data: any) => {
    setIsFetching(true)
    signInSubmit(data, null, setIsFullFilled)
  }

  const generateForm = (inputData: any, uniqueKey: number) => {
    return (
      <div className={styles.formRowSingle} key={uniqueKey}>
        <FormsInputField
          key={inputData.inputName}
          watch={watch}
          register={register}
          errors={errors}
          inputField={inputData}
          clearErrors={null}
          asyncValidate={undefined}
          stylesInput={true}
        />
      </div>
    )
  }
  return (
    <section
      className={styles.popupCustomerExisted}
      onClick={(e) =>
        e.currentTarget === e.target && !isFetching && closePopup(false)
      }
    >
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.header}>{t('login')}</h2>
        <div className={styles.wrapper}>
          {signInFormData1.map((el, idx) => generateForm(el, idx))}
        </div>
        <p
          className={`${styles.buttonLink} ${styles.buttonLine}`}
          onClick={isOpenResetYourPassword}
        >
          {t('forgot-password')}
        </p>
        <div className={`${styles.wrapperButton}`}>
          {isFetching ? (
            <Preloader variant="part" />
          ) : (
            <button className={styles.button} type="submit">
              {t('sign-in')}
            </button>
          )}
        </div>
      </form>
    </section>
  )
}

export default PopupSingIn
