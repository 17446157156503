import isEmail from 'validator/lib/isEmail'

export const signInFormData = [
  {
    fieldType: 'inputField',
    inputName: 'login',
    inputType: 'email',
    inputPlaceholder: 'email',
    labelName: 'email',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      validate: (value) =>
          isEmail(value.trim()) || 'please-fill-field-with-valid-email',
    },
    style3: 'inputInvalid',
    style4: 'textEmail',
  },
  {
    fieldType: 'inputField',
    inputName: 'password',
    inputType: 'password',
    inputPlaceholder: 'password',
    labelName: 'password',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      minLength: {
        value: 5,
        message: 'passwords-length-should',
      },
    },
    style3: 'inputInvalid',
  },
]

export const forgotPasswordFormData = [
  {
    fieldType: 'inputField',
    inputName: 'login',
    inputType: 'email',
    inputPlaceholder: 'email',
    labelName: 'email',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      validate: (value) =>
          isEmail(value.trim()) || 'please-fill-field-with-valid-email',
    },
    style3: 'inputInvalid',
    style4: 'textEmail',
  },
]

export const confirmationPasswordFormData = [
  {
    fieldType: 'inputField',
    inputName: 'password',
    inputType: 'password',
    inputPlaceholder: 'password',
    labelName: 'password',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      minLength: {
        value: 5,
        message: 'passwords-length-should',
      },
    },
    style3: 'inputInvalid',
  },
  {
    fieldType: 'inputField',
    inputName: 'confirmPassword',
    inputType: 'password',
    inputPlaceholder: 'password',
    labelName: 'password',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      minLength: {
        value: 5,
        message: 'passwords-length-should',
      },
    },
    style3: 'inputInvalid',
  },
]

