import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import styles from './Status.module.scss'

const Status = ({ status }) => {
  const [trackNumber, setTrackNumber] = useState(true)
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.statusContainer}>
        <ul>
          <li className={styles.status}>
            <span className={styles.statusImg}>
              <Image
                src="/images/in_transit.svg"
                alt={status}
                width={24}
                height={36}
                objectFit="contain"
              />
            </span>
            <p className={styles.statusText}>{status}</p>
          </li>
        </ul>
        {trackNumber && (
          <p>
            {' '}
            — <a className={styles.statusLink}>{t('view-details')}</a>
          </p>
        )}
      </div>
    </>
  )
}

export default Status
