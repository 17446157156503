import React, { FC } from 'react'
import Image from 'next/image'
import styles from './PreviousOrderElement.module.scss'
import {
  annualmembershipSku,
  trialMembershipSku,
} from '../../../constants/constants'
import useWindowDimensions from '../../../customHooks/useWindowDimension'

const PreviousOrderElement: FC<any> = ({ lineItem, coefficientDiscount }) => {
  const { product, quantity, price, totalDiscount } = lineItem || {}
  const { width } = useWindowDimensions()
  return (
    <div className={styles.orderItem}>
      <div className={styles.orderLabel}>
        <div className={styles.orderPic}>
          {product?.images[0] && (
            <Image
              src={product?.images[0]?.src}
              alt={product?.images[0]?.alt}
              width={40}
              height={40}
              objectFit="contain"
            />
          )}
        </div>
        <div className={styles.orderName}>{lineItem.name}</div>
        {lineItem?.product?.sku !== annualmembershipSku &&
          lineItem?.product?.sku !== trialMembershipSku &&
          lineItem?.product?.sku !== annualmembershipSku?.replace('D_', '') &&
          lineItem?.product?.sku !== trialMembershipSku?.replace('D_', '') && (
            <div className={styles.orderQnt}>
              {width > 479 ? <span>&#120; </span> : null}
              {lineItem?.quantity > 1
                ? `${lineItem.quantity} packs`
                : `${lineItem.quantity} pack`}
            </div>
          )}
      </div>
      <div className={styles.orderPrices}>
        <div className={styles.orderPrice}>{`$${(coefficientDiscount === 1
          ? price * quantity
          : price * quantity - totalDiscount
        ).toFixed(2)}`}</div>
      </div>
    </div>
  )
}

export default PreviousOrderElement
