import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './VitalivButton.module.scss'
import Image from 'next/image'

type VitalivButtonKind = 'primary' | 'secondary'
type VitalivButtonWidth = 'auto' | 'full'

interface VitalivButtonProps {
  kind?: VitalivButtonKind
  width?: VitalivButtonWidth
  text?: string
  action?: typeof defaultAction
  disabled?: boolean
  disabledSecondary?: boolean
  padding?: 'small' | 'large'
  svgPath?: string
  additionStyle?: string
}

const defaultAction = (e: React.MouseEvent<HTMLButtonElement>) =>
  console.log('clicked', e.target)

const VitalivButton: FunctionComponent<VitalivButtonProps> = ({
  kind = 'primary',
  width = 'auto',
  text,
  action = defaultAction,
  disabled = false,
  disabledSecondary = false,
  padding = 'large',
  svgPath,
  additionStyle,
}) => {
  const buttonWidth = width === 'full' ? '100%' : 'auto'
  const buttonPadding = padding === 'large' ? '0.9em 2em' : ' 0.4em 2em'

  const { t }: { t: any } = useTranslation()
  return (
    <button
      className={`${styles.button} ${styles[kind] ?? ''} ${
        disabled ? styles.disabledButton : ''
      } 
      ${disabledSecondary ? styles.disabledButtonWithoutBG : ''}
      
      ${additionStyle ? styles[additionStyle] : ''}`}
      onClick={(e) => action(e)}
      style={{
        width: buttonWidth,
        padding: buttonPadding,
      }}
      disabled={disabled}
    >
      {text ? (
        t(text)
      ) : svgPath ? (
        <Image src={svgPath} alt="icon" width={24} height={24} layout="fixed" />
      ) : (
        ''
      )}
    </button>
  )
}

export default VitalivButton
