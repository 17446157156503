import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { getCookie, setCookies } from 'cookies-next'
import {
  draftOrderIdCookie,
  shopifyCartCookie,
  customerIdentityCookieName,
  VITALIVE_SERVER_URL,
  tripleIdCookie,
  localStatistic,
} from '../constants/constants'
import { refreshToken as refreshTokenFunc } from './refreshTokens'

import { Customer, CustomersCredentials } from '../interfaces/CustomerCheckout'
import { getObjectFromCookie } from './getObjectFromCookie'
import { GetServerSidePropsContext } from 'next'
import { PreviousOrders } from '../interfaces/Orders'

// eslint-disable-next-line no-unused-vars
type OpenPopup = (data: {
  open: boolean
  message: string
  isExist: boolean
}) => void

export const getUserInfo: any = async (
  cookie: {
    accessToken: string
    refreshToken: string
  } | null = null,
  ctx: any | undefined = null
) => {
  let _accessToken

  if (cookie) {
    _accessToken = cookie?.accessToken
  } else {
    let _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {})
    _identity = _identity
      ? getObjectFromCookie(_identity as string).accessToken
      : _identity
    _accessToken = _identity ? _identity : ''
  }

  try {
    const res = await axios.get(`${VITALIVE_SERVER_URL}/customer/info`, {
      headers: {
        Authorization: `Bearer ${_accessToken}`,
      },
    })

    const userInfo = res?.data
    const userDefaultAddress = res?.data?.customerAddresses?.find(
      (el: any) => el.default
    ) ||
      res?.data?.customerAddresses[0] || {
        provinceCode: '',
        province: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
      }
    const ccProvider = userInfo.customerPaymentProvider
      ? {
          card_number: `**** **** **** ${userInfo.customerPaymentProvider.cardLastDigits}`,
          card_expirationDate: '**/**',
          card_cvc: '***',
          card_cardHolder:
            userInfo.customerPaymentProvider.paymentProvider.includes('stripe')
              ? '*********'
              : '',
        }
      : {}

    return {
      userData: {
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        phoneNumber: userInfo?.phoneNumber,
        provinceCode: userDefaultAddress?.provinceCode
          ? userDefaultAddress?.provinceCode
          : userDefaultAddress?.province,
        zip: userDefaultAddress?.zip,
        city: userDefaultAddress?.city,
        address1: userDefaultAddress?.address1,
        address2: userDefaultAddress?.address2,
        email: userInfo?.email,
        customerId: userInfo?.id,
        ...ccProvider,
        // country: userDefaultAddress.country,
      },
      userAddress: res?.data?.customerAddresses,
      customerData: {
        id: userInfo?.id,
        lastName: userInfo?.lastName,
        firstName: userInfo?.firstName,
        membership: userInfo?.membership || {},
        email: userInfo?.email,
        cashbackAmount: userInfo?.cashbackTotal || '',
        totalOrders: userInfo?.totalOrders || 0,
      },
    }
  } catch (err: any) {
    Sentry.captureException(err)
    console.error('getUserInfoERror', err?.response?.data)
    const _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {})
    const _refreshToken = _identity
      ? getObjectFromCookie(_identity as string).refreshToken
      : _identity

    if (err?.response?.status === 401) {
      return refreshTokenFunc(() => getUserInfo(null, ctx), _refreshToken, ctx)
    }
  }
}

export interface ILineItem {
  variant_id: string
  quantity: number
  itemPrice?: any
}

export const getTaxCost = async (
  userInfo: any,
  cashbackAmount: number = 0,
  ctx: any = null,
  customerId?: string,
  discountCode: string | null = null,
  _draftOrderId?: string
) => {
  let shopifyCart = getCookie(shopifyCartCookie, ctx ? ctx : {}) as any
  let draftOrderID = _draftOrderId
    ? _draftOrderId
    : (getCookie(draftOrderIdCookie, ctx ? ctx : {}) as any)
  let tripleId = getCookie(tripleIdCookie, ctx ? ctx : {}) as any
  let _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {}) as any
  let statistic: string | undefined
  if (typeof window !== 'undefined') {
    statistic = window.localStorage.getItem(localStatistic) || undefined
  }

  let accessToken

  if (_identity) {
    accessToken = getObjectFromCookie(_identity as string)?.accessToken
  }

  tripleId = tripleId ? getObjectFromCookie(tripleId as string)._triple_id : ''

  shopifyCart = shopifyCart ? getObjectFromCookie(shopifyCart as string) : null
  const _sendData = {
    tripleId,
    line_items: new Array<ILineItem>(),
    customerShipping: {},
    cashbackAmount: cashbackAmount,
    customerId: customerId ? +customerId : undefined,
    statistic,
  }

  if (userInfo) {
    if (userInfo.card_number) {
      Object.keys(userInfo).forEach((item) => {
        if (!item.includes('card_')) {
          _sendData.customerShipping[item] = userInfo[item]
        }
      })
    } else {
      _sendData.customerShipping = { ...userInfo }
    }
  }

  if (draftOrderID) {
    _sendData['draftOrderID'] = draftOrderID
  }

  if (shopifyCart) {
    if (Object.keys(shopifyCart?.checkoutLineItems).length) {
      Object.keys(shopifyCart?.checkoutLineItems).map((lineItem: any) => {
        if (lineItem && shopifyCart?.checkoutLineItems[lineItem]) {
          _sendData.line_items.push({
            variant_id: shopifyCart?.checkoutLineItems[lineItem].variantId,
            quantity: shopifyCart?.checkoutLineItems[lineItem].quantity,
          })
        }
      })
    } else {
      try {
        const cartInfo = await axios
          .get(
            `${VITALIVE_SERVER_URL}/checkout/order?draftOrderId=${draftOrderID}`
          )
          .then((res) => res.data)

        Object.keys(cartInfo?.line_items).map((lineItem: any) => {
          if (lineItem && cartInfo?.line_items[lineItem]) {
            _sendData.line_items.push({
              variant_id: cartInfo?.line_items[lineItem]?.variant_id,
              quantity: cartInfo?.line_items[lineItem]?.quantity,
            })
          }
        })
      } catch (e) {
        Sentry.captureException(e)
        console.log('checkout/draftOrderID', e)
      }
    }
  }

  if (discountCode) {
    _sendData['discountCode'] = discountCode
  }

  try {
    const res = await axios.post(
      `${VITALIVE_SERVER_URL}/checkout/draft`,
      {
        ..._sendData,
      },
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }
    )

    if (!draftOrderID || _draftOrderId)
      setCookies(
        draftOrderIdCookie,
        res.data.body.draft_order.id,
        ctx ? ctx : {}
      )

    return res.data.body.draft_order ?? null
  } catch (e) {
    Sentry.captureException(e)
    console.log('checkoutDraft', e)
  }
}

export const deliveryCost = async (costOfCart: number, variants?: string) => {
  const convertToCents = +(costOfCart * 100).toFixed(2)
  try {
    const params = {
      costOfCart: convertToCents,
    }

    if (variants) {
      params['variants'] = variants
    }

    const res = await axios.get(
      `${VITALIVE_SERVER_URL}/checkout/deliverycost`,
      {
        params,
      }
    )
    return res.data
  } catch (err: any) {
    Sentry.captureException(err)
    console.error('deliveryCost', err.response)
  }
}

export const isUserExistByCC = async (
  checkCustomerURL: Customer,
  checkCustomerByMail: CustomersCredentials,
  // eslint-disable-next-line no-unused-vars
  setOpenPopup: OpenPopup
) => {
  try {
    const { card_expirationDate, card_cvc, card_number } = checkCustomerURL
    const { phoneNumber, email } = checkCustomerByMail

    let expDate = card_expirationDate.split('/')

    const exp_month = +expDate[0]
    const exp_year = +expDate[1]

    const res = await axios.get(`${VITALIVE_SERVER_URL}/searchCustomer`, {
      params: {
        number: card_number,
        exp_month,
        exp_year,
        cvc: card_cvc,
        phoneNumber,
        email: email.trim(),
      },
    })
    const { existingCard } = res.data
    if (existingCard) {
      setOpenPopup({
        open: true,
        message: 'customer-with-this-data-have-already-existed',
        isExist: true,
      })
    }
  } catch (err) {
    Sentry.captureException(err)
    console.log('checkCustomerHander', err)
  }
}

export const isUserExistByMail = async (
  checkCustomerMailOrPhone: {
    email: string
    phoneNumber: string
  },
  // eslint-disable-next-line no-unused-vars
  setOpenPopup: OpenPopup
) => {
  try {
    const _params: any = {}
    _params['email'] = checkCustomerMailOrPhone.email.trim()
    if (
      checkCustomerMailOrPhone.phoneNumber &&
      checkCustomerMailOrPhone.phoneNumber !== '+1'
    ) {
      _params['phoneNumber'] = checkCustomerMailOrPhone.phoneNumber.trim()
    }
    const res = await axios.post(
      `${VITALIVE_SERVER_URL}/customer/checkbylogin`,
      _params
    )
    const { exists, customer } = res.data

    setOpenPopup(
      exists
        ? {
            open: true,
            message: 'customer-with-this-data-have-already-existed',
            isExist: exists,
          }
        : {
            open: false,
            message: '',
            isExist: exists,
          }
    )

    return customer || undefined
  } catch (err: any) {
    Sentry.captureException(err)
    console.error('isUserExistByMail', err)
  }
}

export const getPreviousOrders = async (
  ctx: GetServerSidePropsContext | null = null
): Promise<PreviousOrders[] | string | undefined> => {
  const _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {})
  const tokens = _identity
    ? getObjectFromCookie(_identity as string)
    : _identity
  try {
    const res = await axios.get(`${VITALIVE_SERVER_URL}/customer/orders`, {
      headers: {
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    console.error('getPreviousOrders: ', err?.response?.data)
    if (err?.response?.status === 401) {
      return refreshTokenFunc(
        () => getPreviousOrders(ctx),
        tokens?.refreshToken,
        ctx
      )
    }
  }
}
