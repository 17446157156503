import React, {
  FunctionComponent,
  useState,
  useEffect,
  memo,
  MouseEvent,
  useCallback,
  useRef,
} from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './ProductCard.module.scss'
import { ProductDetails } from '../../../interfaces/CartContent'
import { QuantitySelector, ViewCartButton } from '../index'
import Preloader from '../Preloader/Preloader'
import cn from 'classnames'
import { debounce } from 'lodash'
import stylesButton from '../QuantitySelector/QuantitySelector.module.scss'
import error404 from '../Error404/Error404'
import { background } from '@storybook/theming'
import dynamic from 'next/dynamic'
const JudgeMe = dynamic(() => import('../../common/JudgeMe/JudgeMe'))

interface ProductCardProps {
  data: ProductDetails
  quantity: number
  updateShopifyCart: (
    type: 'add' | 'remove' | 'update',
    quantity?: number | undefined
  ) => Promise<void>
  icons: any
  breadCrumbGoal?: {
    title: string
  }
  breadCrumbCategory?: string
  reconciliation?: boolean
  removeProduct?: () => void
  onSelect?: (id: string) => void
  restorationRef?: any
  coefficientDiscount?: number
  styleSlider?: boolean
}

const MemorizedProductcard: FunctionComponent<ProductCardProps> = ({
  data,
  quantity,
  updateShopifyCart,
  icons,
  breadCrumbGoal,
  breadCrumbCategory,
  reconciliation,
  removeProduct,
  onSelect,
  restorationRef,
  coefficientDiscount,
  styleSlider,
}) => {
  const [customUrl, setCustomUrl] = useState<string[]>([])
  const router = useRouter()
  const { t }: { t: any } = useTranslation()

  const [isLoading, setIsLoading] = useState(reconciliation)
  const stateRef = useRef<any>()
  stateRef.current = reconciliation

  const [quantityToSend, setQuantityToSend] = useState<number>(quantity)
  const debouncedFunctionRef = useRef<any>()

  debouncedFunctionRef.current = () => {
    if (!quantityToSend) return
    updateShopifyCart('add', quantityToSend)
  }

  const debounceFuncUpdateCart = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), 500),
    []
  )

  useEffect(() => {
    if (quantityToSend === 0) setIsLoading(true)
  }, [quantityToSend])

  const handleUpdateCart = (callback: () => void) => {
    if (reconciliation && stateRef.current)
      return setTimeout(() => {
        handleUpdateCart(callback)
      }, 100)
    return callback()
  }

  useEffect(() => {
    if (icons.length > 0) {
      icons.forEach((icon: any, idx: number) => {
        if (icon.icon) {
          setCustomUrl((_prev: string[]) => [
            ..._prev,
            `url('/images/FilterIcons/GoalsIcons/${icon.icon}.svg') 5% ${
              idx === 0 ? 20 : 20 + idx * 13
            }% no-repeat `,
          ])
        }
      })
    }
  }, [])

  const handleChangeQuantity = async (type: 'add' | 'remove') => {
    if (type === 'add') {
      setQuantityToSend((prev) => prev + 1)
      if (quantityToSend === undefined) {
        handleUpdateCart(() => updateShopifyCart('add'))
        setIsLoading(true)
      } else {
        handleUpdateCart(() => debounceFuncUpdateCart())
      }
    } else {
      if (quantityToSend - 1 === 0) {
        handleUpdateCart(
          () => (removeProduct && removeProduct()) || debounceFuncUpdateCart()
        )
      } else {
        handleUpdateCart(() => debounceFuncUpdateCart())
      }
      setQuantityToSend((prev) => (prev > 1 ? prev - 1 : 0))
    }
  }

  useEffect(() => {
    if (!quantityToSend) setQuantityToSend(quantity)
  }, [quantity])

  useEffect(() => {
    if (!reconciliation) setIsLoading(false)
  }, [reconciliation])

  const redirectToDetails = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    // why in the product url with filters
    const _goal = breadCrumbGoal ? `&purpose=${breadCrumbGoal.title}` : ''
    const _category = breadCrumbCategory
      ? `?category=${breadCrumbCategory}`
      : ''
    // router.push(`/product/${data.id}${_category}${_goal}`)

    onSelect && onSelect(data.id)

    router.push(`https://shop.vitaliv.us/products/${data.id}`)
  }

  const redirectToCart = (event: MouseEvent) => {
    event.stopPropagation()
    router.push(`/cart`)
  }

  useEffect(() => {
    // restorationRef is only provided to the ProductCard that needs to be scrolled to
    if (!restorationRef) {
      return
    }
    restorationRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
  }, [])

  const category = data.collections.find(
    (item) => item.type === 'category'
  )?.title

  return (
    <>
      <div
        className={cn(styles.card, { [styles.imagesBlockNew]: styleSlider })}
        ref={restorationRef}
      >
        <div
          className={cn(styles.imageBlock, {
            [styles.imagesBlockNew]: styleSlider,
          })}
          onClick={(event) => redirectToDetails(event)}
        >
          <span
            className={cn(styles.goalIcons, {
              [styles.goalIconsError]: styleSlider,
            })}
            style={{
              background: customUrl.join(','),
            }}
          ></span>
          <div
            className={cn(styles.images, { [styles.imagesNew]: styleSlider })}
          >
            <div className={`${styles.postMiniSliderTop} ${styles['orange']}`}>
              {data?.images?.map((image, index) => {
                return (
                  <span
                    key={image.src}
                    className={index + 1 === 1 ? styles.active : ''}
                  />
                )
              })}
            </div>
            {styleSlider ? (
              <span className={styles.desktopSliderImage}>
                <p
                  style={{ background: data.productColor }}
                  className={styles.categoryText}
                >
                  {category}
                </p>
                <div className={styles.desktopImage}>
                  <Image
                    src={data?.images ? data?.images[0]?.src : ''}
                    alt={data.title}
                    width={450}
                    height={487}
                    objectFit="contain"
                  />
                </div>

                <div className={styles.mobileImage}>
                  <Image
                    src={data?.images ? data?.images[0]?.src : ''}
                    alt={data.title}
                    width={316}
                    height={315}
                    objectFit="contain"
                  />
                </div>
              </span>
            ) : (
              <span className={styles.desktopSliderImage}>
                <Image
                  src={data?.images ? data?.images[0]?.src : ''}
                  alt={data.title}
                  width={200}
                  height={200}
                  objectFit="contain"
                />
              </span>
            )}
          </div>
        </div>

        <div className={styles.wrapperBlock} onClick={redirectToDetails}>
          <JudgeMe renderBlock="productReview" />
          <p className={cn(styles.header, { [styles.headerNew]: styleSlider })}>
            {data.title}
          </p>
          <p
            className={cn(styles.subtitle, {
              [styles.subtitleShop]: styleSlider,
            })}
          >
            {data.subtitle}
          </p>
          <p
            className={cn(styles.textTitle, {
              [styles.textTitleShop]: styleSlider,
            })}
          >
            {data.shortdescription}
          </p>
        </div>
        <div className={cn(styles.wrapperButton)}>
          {styleSlider ? (
            <div
              className={cn(
                styles.wrapperBlockPrice,
                styles.wrapperBlockPriceNew
              )}
              onClick={redirectToDetails}
            >
              <div>
                <p className={`${styles.priceNew}`}>${data.price}</p>
                <p className={styles.textNew}>You Pay</p>
              </div>
              <div>
                <p className={`${styles.priceNewTwo}`}>
                  $
                  {coefficientDiscount === 1
                    ? data.compare_at_price
                    : (
                        +data.compare_at_price * (coefficientDiscount || 1)
                      ).toFixed(2)}
                </p>
                <p className={styles.textPrice}>
                  {styleSlider ? (
                    <p className={styles.textPriceNew}>Typical Price</p>
                  ) : (
                    <p className={styles.textPriceNew}>
                      {coefficientDiscount === 1
                        ? t('suggested-price')
                        : t('regular-price')}
                    </p>
                  )}
                </p>
              </div>
            </div>
          ) : (
            <div
              className={styles.wrapperBlockPrice}
              onClick={redirectToDetails}
            >
              <div className={styles.wrapperText}>
                <p className={styles.text}>{t('members-price')}</p>
                <p className={`${styles.text} ${styles.cost}`}>${data.price}</p>
              </div>
              <div className={styles.wrapperText}>
                <p className={styles.textPrice}>
                  <p className={styles.textPrice}>
                    {coefficientDiscount === 1
                      ? t('suggested-price')
                      : t('regular-price')}
                  </p>
                </p>
                <p className={`${styles.textPrice} ${styles.costPrice}`}>
                  $
                  {coefficientDiscount === 1
                    ? data.compare_at_price
                    : (
                        +data.compare_at_price * (coefficientDiscount || 1)
                      ).toFixed(2)}
                </p>
              </div>
            </div>
          )}
          {styleSlider ? null : (
            <div className={styles.buttonDetails} onClick={redirectToDetails}>
              <Image
                src="/images/details.svg"
                alt="Details"
                width={24}
                height={24}
                objectFit="contain"
                objectPosition="center"
              />
              <span className={styles.textDetails}>{t('details')}</span>
            </div>
          )}
          {styleSlider ? null : (
            <div
              className={cn(styles.selectBlock, {
                [styles.selectBlockLoader]: isLoading,
              })}
            >
              {isLoading ? (
                <div className={styles.preloader}>
                  <Preloader variant="part" />
                </div>
              ) : (
                <>
                  <QuantitySelector
                    subtractQuantity={() => handleChangeQuantity('remove')}
                    addQuantity={() => handleChangeQuantity('add')}
                    quantity={quantityToSend}
                    product_sku={data.sku}
                    productPrice={data.price}
                    stylesBorder={stylesButton.quantitySelectorCard}
                  />
                  {quantityToSend ? (
                    <div
                      className={styles.buttonViewCart}
                      onClick={redirectToCart}
                    >
                      <ViewCartButton />
                    </div>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const ProductCard = memo(MemorizedProductcard)
export default ProductCard
