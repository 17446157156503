import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSubmitForms } from '../../../../customHooks/useSubmitForms'
import { forgotPasswordFormData } from '../../../Account/Signin/staticData'
import FormsInputField from '../../FormsInputField/FormsInputField'
import Preloader from '../../Preloader/Preloader'
import styles from './PopupResetYourPassword.module.scss'

type MyProps = {
  closePopup: (closePopup: boolean) => void
  userEmail: string
}

const PopupResetYourPassword: FC<MyProps> = ({ closePopup, userEmail }) => {
  const { t }: { t: any } = useTranslation()
  const [isFullfilled, setIsFullFilled] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const { forgotPassword } = useSubmitForms()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: { login: userEmail },
  })

  const onSubmit: SubmitHandler<{ login: string }> = async (data) => {
    setIsFetching(true)
    await forgotPassword(data, null, setIsFullFilled)
    setIsFetching(false)
  }
  return (
    <section
      className={styles.popupCustomerExisted}
      onClick={(e) =>
        e.currentTarget === e.target && !isFetching && closePopup(false)
      }
    >
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.header}>{t('reset-password')}</h2>
        {!isFullfilled ? (
          <label className={styles.labelText}>
            {t('we-will-send-you-an-email-to-reset-your-password')}
            <FormsInputField
              key="login"
              watch={watch}
              register={register}
              errors={errors}
              inputField={forgotPasswordFormData[0]}
              clearErrors={null}
              asyncValidate={undefined}
              stylesInput={true}
            />
          </label>
        ) : (
          <p className={styles.passwordText}>
            {t('password-reset-link-has-been')}
          </p>
        )}
        {isFetching ? (
          <div className={styles.preLoaderStyle}>
            <Preloader variant="part" />
          </div>
        ) : (
          <div className={styles.wrapperButton}>
            {!isFullfilled && (
              <button className={styles.button}>{t('submit')}</button>
            )}
            <button
              className={styles.buttonLink}
              onClick={() => closePopup(false)}
            >
              {t('cancel')}
            </button>
          </div>
        )}
      </form>
    </section>
  )
}

export default PopupResetYourPassword
