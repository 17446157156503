import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import styles from '../FormsInputField.module.scss'
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { FormFieldInterface } from '../../../../interfaces/FormsInterfaces'

type MyProps = {
  watch: (fieldName: string) => string
  register: UseFormRegister<any>
  errors: any
  inputField: FormFieldInterface
  asyncValidate?: null | ((customer: any) => void)
  setValue?: UseFormSetValue<FieldValues>
}

const CheckBoxFormField: FC<MyProps> = ({
  //   watch,
  register,
  //   errors,
  inputField,
  //   asyncValidate,
  setValue,
}) => {
  const { t }: { t: any } = useTranslation()
  return (
    <label className={styles.containerRadioButton}>
      {!inputField?.withInput ? (
        <div className={styles.wrapper}>
          <p className={styles.textRadio}>{t(inputField?.labelName)}</p>
        </div>
      ) : null}
      <input
        {...register(inputField?.inputName, inputField?.validate)}
        className={styles.radioButton}
        type={inputField?.inputType}
        placeholder={inputField?.inputPlaceholder}
        name={inputField?.inputName}
        value={inputField?.value}
      />
      <span className={styles.checkmark}></span>
      {inputField?.withInput ? (
        <input
          onFocus={() => setValue && setValue(inputField?.inputName, 'other')}
          {...register('other', inputField?.validate)}
          className={styles.inputWithRadio}
          placeholder={inputField?.inputPlaceholder}
          name="other"
        />
      ) : null}
    </label>
  )
}

export default CheckBoxFormField
