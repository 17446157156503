import React, { FC, useRef } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { ProductCard } from '../index'

import { Navigation, Pagination } from 'swiper'

import 'swiper/css/pagination'
import styles from './CrossSalesSlider.module.scss'
import { useCartContext } from '../../../contexts/CartContext'
import { ProductDetails } from '../../../interfaces/CartContent'

type MyProps = {
  crossSale: ProductDetails[]
  setShopifyCart: any
  cartContent: any
  breadCrumbsGoal?: { title: string; id: string }
  breadCrumbsCategory?: string
  isWebView?: boolean
  reconciliation?: boolean
  removeProduct: (frontVariantId: string, isDebounced?: boolean) => void
  coefficientDiscount?: number
}

const CrossSalesSliders: FC<MyProps> = ({
  crossSale,
  setShopifyCart,
  cartContent,
  breadCrumbsGoal,
  breadCrumbsCategory,
  reconciliation,
  removeProduct,
  coefficientDiscount,
}) => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const paginationRef = useRef(null)

  return (
    <section className={styles.container}>
      <div className={styles.slider}>
        <Swiper
          className={styles.slider__wrapper}
          spaceBetween={20}
          // navigation
          modules={[Navigation, Pagination]}
          pagination={{
            el: paginationRef.current,
            clickable: true,
            bulletActiveClass: styles.swiperPaginationBulletActive,
          }}
          loop
          slidesPerView={3}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 860px
            860: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}
        >
          {crossSale.map((product: any) => {
            return (
              <SwiperSlide key={product.id} className={styles.slider__items}>
                <ProductCard
                  key={product.title}
                  data={product}
                  quantity={cartContent[product.frontVariantId]?.quantity}
                  updateShopifyCart={setShopifyCart(
                    product.frontVariantId,
                    product.variantId
                  )}
                  icons={product.collections.filter(
                    (el: any) => el.type === 'goal'
                  )}
                  breadCrumbGoal={breadCrumbsGoal}
                  breadCrumbCategory={breadCrumbsCategory}
                  reconciliation={reconciliation}
                  removeProduct={() => removeProduct(product.frontVariantId)}
                  coefficientDiscount={coefficientDiscount}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div
          className={styles.slider_button_next}
          ref={navigationNextRef}
        ></div>
        <div
          className={styles.slider_button_prev}
          ref={navigationPrevRef}
        ></div>
        <div
          className={`${styles.swiperCustomPagination} ${styles['orange']}`}
          ref={paginationRef}
        >
          {crossSale.map((el, idx) => (
            <span key={idx} className={styles.customPaginationBullets}></span>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CrossSalesSliders
