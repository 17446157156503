import React from 'react'
import { useTranslation } from 'next-i18next'
import InputMask from 'react-input-mask'
import styles from '../FormsInputField.module.scss'

// type MyProps = {
//   watch: (fieldName: string) => string
//   register: (fieldName: string, params: any) => void
//   errors: any
//   inputField: any
//   asyncValidate?: null | ((customer: any) => void)
//   clearErrors?: any
// }

const FormInputMask = ({
  watch,
  register,
  errors,
  inputField,
  // asyncValidate,
}) => {
  const { t } = useTranslation()

  const beforeMaskedValueChange = (newState) => {
    let { value } = newState
    const selection = newState.selection
    if (value === '+1-') {
      value = value.substring(0, value.lastIndexOf('-'))
    }
    return {
      value,
      selection,
    }
  }

  return (
    <div
      className={`${styles.inputWrapper} ${
        watch(inputField.inputName) ? styles.hasInput : ''
      }`}
    >
      <span
        className={
          errors[inputField.inputName]
            ? styles.inputLabelError
            : styles.inputLabel
        }
      >
        {t(inputField.labelName)}
      </span>
      <InputMask
        alwaysShowMask
        mask={inputField.mask}
        maskChar={inputField.maskChar}
        formatChars={inputField.formatChars}
        {...register(inputField.inputName, inputField.validate)}
        beforeMaskedValueChange={
          inputField.isBeforeValueChange ? beforeMaskedValueChange : null
        }
        type={inputField.inputType}
        name={inputField.inputName}
        className={inputField.style ? styles[inputField.style] : ''}
        placeholder={t(inputField.inputPlaceholder)}
        value={`${watch(inputField.inputName)}`}
      />
      {errors[inputField.inputName] && (
        <span className={styles.errorMessage}>
          {t(errors[inputField.inputName].message)}
        </span>
      )}
    </div>
  )
}

export default FormInputMask
