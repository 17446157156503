import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { UseFormRegister } from 'react-hook-form'
import styles from '../FormsInputField.module.scss'
import { FormFieldInterface } from '../../../../interfaces/FormsInterfaces'

type MyProps = {
  watch: (fieldName: string) => string
  register: UseFormRegister<any>
  errors: any
  inputField: FormFieldInterface
  //   asyncValidate?: null | ((customer: any) => void)
}

const FormTextArea: FC<MyProps> = ({
  watch,
  register,
  errors,
  inputField,
  //   asyncValidate,
}) => {
  const { t }: { t: any } = useTranslation()
  return (
    <div
      className={`${styles.inputWrapper} ${
        watch(inputField.inputName) ? styles.hasInput : ''
      }`}
    >
      <span
        className={
          errors[inputField.inputName]
            ? styles.inputLabelError
            : styles.inputLabel
        }
      >
        {t(inputField.labelName)}
      </span>
      <textarea
        {...register(inputField?.inputName, inputField?.validate)}
        name={inputField.inputName}
        className={` ${styles.textAreaField} ${
          inputField.style ? styles[inputField.style] : ''
        } `}
        placeholder={t(inputField.inputPlaceholder)}
      />
      {errors[inputField.inputName] && (
        <span className={styles.errorMessage}>
          {t(errors[inputField.inputName].message)}
        </span>
      )}
    </div>
  )
}

export default FormTextArea
