import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import InputMask from 'react-input-mask'
import {
  CustomerCreditCardForm,
  Customer,
} from '../../../interfaces/CustomerCheckout'
import styles from './FormsInputField.module.scss'
import classNames from 'classnames'
import Image from 'next/image'

// type MyProps = {
//   watch: (fieldName: string) => string
//   register: (fieldName: string, params: any) => void
//   errors: any
//   inputField: CustomerCreditCardForm
//   asyncValidate?: null | ((customer: any) => void)
//   clearErrors?: any
// }

const FormsInputField = ({
  watch,
  register,
  errors,
  inputField,
  asyncValidate,
  clearErrors,
  stylesInput,
}) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState('password')

  const handleShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
    } else {
      setShowPassword('password')
    }
  }

  const changeHandler = () => {
    if (clearErrors) {
      clearErrors(null)
    }
  }
  return (
    <div
      className={`${styles.inputWrapper} ${
        watch(inputField.inputName) ? styles.hasInput : ''
      }`}
    >
      {/* <span
        className={
          errors[inputField.inputName]
            ? styles.inputLabelError
            : styles.inputLabel
        }
      >
        {t(inputField.labelName)}
      </span> */}
      {inputField.fieldType === 'inputField' ? (
        <input
          {...register(
            inputField.inputName,
            clearErrors
              ? {
                  ...inputField.validate,
                  onChange: () => {
                    changeHandler()
                  },
                }
              : inputField?.validate
          )}
          className={`${
            inputField.style ? styles[inputField.style] : styles.input_field
          }
            ${errors[inputField.inputName] ? styles[inputField.style3] : ''} ${
            styles.input
          }
          ${inputField.labelName === 'email' && styles[inputField.style4]}
          ${stylesInput && styles.textAreaFieldPopup}
          `}
          name={inputField.inputName}
          type={
            inputField.inputType !== 'password'
              ? inputField.inputType
              : showPassword
          }
          placeholder={' '}
          autoCapitalize={inputField.labelName === 'email' && 'none'}
        />
      ) : (
        <InputMask
          mask={inputField.mask}
          maskChar={inputField.maskChar}
          formatChars={inputField.formatChars}
          {...register(inputField.inputName, {
            ...inputField.validate,
            onChange: () => {
              changeHandler()
            },
          })}
          type={inputField.inputType}
          name={inputField.inputName}
          className={inputField.style ? styles[inputField.style] : ''}
          placeholder={t(inputField.inputPlaceholder)}
        />
      )}

      {inputField.fieldType === 'inputField' && (
        <label
          htmlFor={inputField.inputPlaceholder}
          className={classNames(styles.placeholder, {
            [styles.placeholderError]: errors[inputField.inputName],
          })}
        >
          {t(inputField.labelName)}
        </label>
      )}

      {inputField.inputType === 'password' && (
        <button type="button" className={styles.buttonShowPass}>
          <Image
            src={
              showPassword === 'password'
                ? '/images/hide_pass.svg'
                : '/images/show_pass.svg'
            }
            alt="close"
            onClick={handleShowPassword}
            width={24}
            height={24}
            objectFit="contain"
          />
        </button>
      )}

      {errors[inputField.inputName] && (
        <span className={styles.errorMessage}>
          {t(errors[inputField.inputName].message)}
        </span>
      )}
    </div>
  )
}

export default FormsInputField
