import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { getCookie, setCookies } from 'cookies-next'
import {
  VITALIVE_SERVER_URL,
  shopifyCartCookie,
  customerIdentityCookieName,
  shopifyClient,
  path,
  trialMembershipSku,
} from '../constants/constants'
import { ILineItem, ProductDetails } from '../interfaces/CartContent'
import { parseJson } from './jsonUtils'

export const customerMembership = async (
  ssrData: {
    ctx: any
    productIds: string | null
  } | null = null,
  setCartContent: any = null,
  isReturningIncludedMembership: boolean = false,
  customer?: any
) => {
  let shopifyCookie = parseJson<any>(
    `${getCookie(shopifyCartCookie, ssrData?.ctx ? ssrData.ctx : null)}`
  )
  const _identity = getCookie(
    customerIdentityCookieName,
    ssrData?.ctx ? ssrData.ctx : null
  )

  const tokens = _identity ? parseJson<any>(`${_identity}`) : undefined

  const _accessToken = _identity !== undefined ? tokens?.accessToken : null

  const _checkoutLineItems = shopifyCookie
    ? shopifyCookie.checkoutLineItems
    : {}
  const _checkoutId = shopifyCookie ? shopifyCookie.checkoutId : ''
  let _withoutDeleteElement = { ..._checkoutLineItems }
  let _newItem: null | ILineItem = null
  let _newFrontId = ''
  const ctx = ssrData ? ssrData.ctx : null
  let productIds = ssrData ? ssrData.productIds : null
  let _countOfProducts = 0

  try {
    const memberShip = await axios.get(
      customer &&
        (customer?.membership?.type === trialMembershipSku ||
          customer?.membership?.type === trialMembershipSku?.replace('D_', ''))
        ? `${VITALIVE_SERVER_URL}/product/addons?status=${customer?.membership?.status}&validUntil=${customer?.membership?.validUntil}&type=${customer?.membership?.type}`
        : `${VITALIVE_SERVER_URL}/product/addons`,
      {
        headers: {
          Authorization: _accessToken ? `Bearer ${_accessToken}` : '',
        },
      }
    )

    const _productsForInclude: {
      variantId: string
      quantity: number
    }[] = []
    const _productsForExclude: string[] = []

    memberShip?.data?.productsForInclude.forEach((element: ProductDetails) => {
      if (!_checkoutLineItems[element.frontVariantId]) {
        _productsForInclude.push({
          variantId: element.frontVariantId,
          quantity: 1,
        })
        _newFrontId = element.frontVariantId
        _newItem = {
          checkoutLineItemId: '',
          quantity: 1,
          itemPrice: element.price,
          variantId: element.variantId,
          isMembership: true,
        }
        // if (setCartContent) {
        //   setCartContent(checkoutItems)
        // } else {
        //   setCookies(
        //     shopifyCartCookie,
        //     {
        //       ...shopifyCookie,
        //       countOfProducts: Object.keys(checkoutItems).length,
        //       checkoutLineItems: checkoutItems,
        //     },
        //     {
        //       ...ctx,
        //       ...path,
        //     }
        //   )
        // }
      }
      if (productIds !== null) {
        productIds += `${productIds ? '&variantIds' : 'variantIds'}=${
          element.variantId
        }`
      }
    })

    memberShip?.data?.productsForExclude.forEach((element: ProductDetails) => {
      if (_checkoutLineItems[element.frontVariantId]) {
        _productsForExclude.push(
          _checkoutLineItems[element?.frontVariantId]?.checkoutLineItemId
        )
        _withoutDeleteElement = Object.keys(_withoutDeleteElement).reduce(
          (acc, key) =>
            key === element.frontVariantId
              ? acc
              : { ...acc, [key]: _withoutDeleteElement[key] },
          {}
        )

        if (productIds !== null) {
          productIds = productIds.replace(
            `${productIds ? '&variantIds' : 'variantIds'}=${element.variantId}`,
            ''
          )
        }
      }
    })

    if (_productsForInclude.length) {
      await shopifyClient.checkout.addLineItems(
        _checkoutId,
        _productsForInclude
      )
    }
    if (_productsForExclude.length) {
      await shopifyClient.checkout.removeLineItems(
        _checkoutId,
        _productsForExclude
      )
    }

    if (setCartContent) {
      const _setNewCartContent = _newItem
        ? {
            ..._withoutDeleteElement,
            [_newFrontId]: _newItem,
          }
        : { ..._withoutDeleteElement }

      setCartContent(_setNewCartContent)
    } else {
      Object.keys(_withoutDeleteElement).forEach((element) => {
        if (
          _withoutDeleteElement[element] &&
          _withoutDeleteElement[element].isMembership
            ? +_withoutDeleteElement[element].itemPrice > 0
            : _withoutDeleteElement[element] &&
              !_withoutDeleteElement[element].isMembership
        ) {
          _countOfProducts += _withoutDeleteElement[element].quantity
        }
      })

      setCookies(
        shopifyCartCookie,
        {
          ...shopifyCookie,
          countOfProducts: _countOfProducts,
          checkoutLineItems: {
            ..._withoutDeleteElement,
            [_newFrontId]: _newItem,
          },
        },
        {
          ...ctx,
          ...path,
        }
      )
    }

    if (productIds !== null) {
      return productIds
    }
    if (isReturningIncludedMembership) {
      return memberShip?.data?.productsForInclude
    }
  } catch (err) {
    Sentry.captureException(err)
    console.log('isMembership: ', err)
  }
}
