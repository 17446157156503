import React, { FC } from 'react'
import axios from 'axios'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import {
  VITALIVE_SERVER_URL,
  customerIdentityCookieName,
  draftOrderIdCookie,
} from '../../../constants/constants'
import { VitalivButton } from '..'
import { customerAddress } from '../../../interfaces/CustomerCheckout'
import { refreshToken } from '../../../utilities/refreshTokens'

import styles from './ChangeAddress.module.scss'

type MyProps = {
  user: {
    firstName: string
    lastName: string
    zip: string
    city: string
    phoneNumber: string
    address1: string
    email: string
    customerId: number
  }
  changeAddress: (openForm: boolean) => void
  formModel: any
}

const ChangeAddressForm: FC<MyProps> = ({ user, changeAddress, formModel }) => {
  const { t }: { t: any } = useTranslation()
  const [cookie] = useCookies()
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      zip: user['zip'],
      city: user['city'],
      address1: user['address1'],
    },
  })

  const onSubmit = async (data: any) => {
    try {
      const dataToSend = {
        ...user,
        ...data,
        draftOrderID: cookie[draftOrderIdCookie],
      }
      await axios.post(
        `${VITALIVE_SERVER_URL}/checkout`,
        {
          customerShipping: dataToSend,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie[customerIdentityCookieName].accessToken}`,
          },
        }
      )
    } catch (err: any) {
      if (err.response.status === 401) {
        refreshToken(() => onSubmit(data))
      }
    }
  }

  const formGenerate = (inputDatas: any) => {
    if (inputDatas instanceof Array) {
      return inputDatas.map((inputField: customerAddress) => (
        <label className={styles.formItem} key={inputField.inputName}>
          <input
            type={inputField.inputType}
            className={styles.formInput}
            {...register(inputField.inputName, inputField.validate)}
            name={inputField.inputName}
            placeholder={inputField.inputPlaceholder}
          />
        </label>
      ))
    }
    return (
      <label className={styles.formItem} key={inputDatas.inputName}>
        <input
          type={inputDatas.inputType}
          className={styles.formInput}
          {...register(inputDatas.inputName, inputDatas.validate)}
          name={inputDatas.inputName}
          placeholder={inputDatas.inputPlaceholder}
        />
      </label>
    )
  }

  return (
    <section className={styles.root}>
      <div className={styles.addressForm}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          {formModel.map((inputField: any) => formGenerate(inputField))}

          <p className={styles.formItem}></p>
          <label className={styles.formItem}>
            <span className={styles.formInput}>{t('usa')}</span>
          </label>

          <div className={styles.addressFormButton}>
            <VitalivButton
              action={handleSubmit(onSubmit)}
              data-wait="Please wait..."
              data-regular-text="Save"
              data-confirm="Are you sure?"
              text="save"
            />
            <VitalivButton
              action={() => changeAddress(true)}
              data-wait="Please wait..."
              data-regular-text="Cancel"
              data-confirm="Are you sure?"
              text="cancel"
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default ChangeAddressForm
