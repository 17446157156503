import { ProductDetails } from '../interfaces/CartContent'
import { DraftOrderLineItem, LineItem } from '../interfaces/ShopifyInterface'

export const isSame = (
  value: string | number | boolean | null,
  initVal: string | number | boolean | null
) => {
  let hashInp = 0
  let hashUser = 0

  if (value === null && initVal === null) {
    return true
  }

  if (
    value === null ||
    value === undefined ||
    initVal === null ||
    initVal === undefined
  ) {
    return false
  }

  if (typeof value === 'boolean') {
    return value === initVal
  }

  if (typeof value === 'number' && typeof initVal === 'number') {
    return initVal === value
  }

  if (typeof value === 'string' && typeof initVal === 'string') {
    for (let i = 0; i < value.length; i++) {
      let char = value.charCodeAt(i)
      hashInp = (hashInp << 5) - hashInp + char
      hashInp = hashInp & hashInp // Convert to 32bit integer
    }
    for (let i = 0; i < initVal.length; i++) {
      let char = value.charCodeAt(i)
      hashUser = (hashUser << 5) - hashUser + char
      hashUser = hashUser & hashUser // Convert to 32bit integer
    }
  }
  return hashInp === hashUser
}

export const compareCheckoutLineItems = (
  draftOrderLineItems: DraftOrderLineItem[],
  checkoutLineItems: LineItem[]
): boolean => {
  for (let i = 0; i < checkoutLineItems.length; ++i) {
    let foundedProduct = draftOrderLineItems.find(
      (el) => el.sku === checkoutLineItems[i]?.variant?.sku
    )
    if (!foundedProduct) {
      return false
    }
  }

  return true
}

export const compareDraftLineItems = (
  draftOrderLineItems: DraftOrderLineItem[],
  checkoutLineItems: any[]
): boolean => {
  for (let i = 0; i < checkoutLineItems.length; ++i) {
    let foundedProduct = draftOrderLineItems.find(
      (el) => el.sku === checkoutLineItems[i]?.sku
    )
    if (!foundedProduct) {
      return false
    }
  }

  return true
}

export const compareProducts = (
  draftOrderLineItems: DraftOrderLineItem[],
  checkoutLineItems: ProductDetails[]
) => {
  for (let i = 0; i < checkoutLineItems.length; ++i) {
    let foundedProduct = draftOrderLineItems.find(
      (el) => el.sku === checkoutLineItems[i]?.sku
    )
    if (!foundedProduct) {
      return false
    }
  }

  return true
}
