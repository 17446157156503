import React from 'react'

import styles from './TotalPrice.module.scss'
type MyProps = {
  totalPrice: number | string
  title: string
}

const TotalPrice = ({ totalPrice, title }: MyProps) => {
  return (
    <div
      className={`${styles.totalContainer} ${
        title === 'Total' && styles.totalContainerMobile
      }`}
    >
      <h2
        className={`${styles.total} ${styles.totalTitle} ${
          title === 'Total' && styles.totalTitleMobile
        }`}
      >
        {title}
      </h2>
      <p
        className={`${styles.total} ${
          title === 'Total' && styles.totalTitleMobile
        }`}
      >
        ${totalPrice}
      </p>
    </div>
  )
}

export default TotalPrice
