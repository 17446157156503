import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

import { useCustomerDataContext } from '../../../contexts/CustomerDataContext'
import { getTaxCost } from '../../../utilities/getUserInfo'
import styles from './BlockShippmentAddress.module.scss'
import { OrderClass } from '../../../interfaces/OrderTypes'

const ShippmentAddress = () => {
  const customerContext = useCustomerDataContext()
  const { userAddress, resetForm, userInfo } = customerContext.dataContext
  const updateFormValues = (address: any) => {
    const addressDataForForm = {
      firstName: address.firstName,
      lastName: address.lastName,
      phoneNumber: address.phone,
      // company: address.company,
      provinceCode: address.provinceCode,
      zip: address.zip,
      city: address.city,
      address1: address.address1,
      address2: address.address2,
      email: address.email,
      country: 'United States',
      card_number: userInfo.card_number,
      card_expirationDate: userInfo.card_expirationDate,
      card_cvc: userInfo.card_cvc,
      card_cardHolder: userInfo.card_cardHolder,
    }

    getTaxCost(addressDataForForm).then((res) => {
      customerContext.setDataContext((_prev: any) => ({
        ..._prev,
        taxPrice: +res?.total_tax,
        maxCashbackAmount: res['note_attributes']?.find(
          (item: OrderClass) => item.name === 'maxCashbackForSpending'
        )?.value,
        distancePrice: {
          ..._prev.distancePrice,
          cost:
            (
              res?.total_shipping_price_set?.presentment_money?.amount * 100
            ).toFixed(2) || 0,
        },
      }))
    })
    resetForm({ ...addressDataForForm })
  }

  const getAddressTitle = (name: string) => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name
  }
  return (
    <>
      <h2 className={styles.header}>Your previous delivery addresses</h2>

      {userAddress && userAddress.length ? (
        <Swiper
          spaceBetween={50}
          slidesPerView={2}
          pagination={true}
          modules={[Navigation, Pagination]}
          className={styles.swiperMain}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
          }}
        >
          <div className={styles.wrapper}>
            {userAddress.map((address: any) => (
              <SwiperSlide
                key={address.id}
                onClick={() => updateFormValues(address)}
              >
                <div key={address.id} className={styles.container}>
                  <h2 className={styles.title}>
                    {getAddressTitle(address.address1)}
                  </h2>
                  <p className={styles.address}>
                    {`${address.address2}, ${address.city}, ${address.provinceCode}, ${address.zip}, USA`}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      ) : (
        <span>There is no address</span>
      )}
    </>
  )
}

export default ShippmentAddress
