import jwtDecode from 'jwt-decode'
import { customerIdentityCookieName } from '../constants/constants'
import { parseJson } from './jsonUtils'

export const getCustomerData = async (cookiesArray) => {
  let customerData = {
    id: null,
    lastName: '',
    firstName: '',
    membership: {},
    email: '',
  }
  const customerId = getCustomerId(cookiesArray)
  if (customerId === null) return customerData

  return (customerData = { ...customerId })
}

const getCustomerId = (cookiesArray) => {
  let customerId = null
  let tokens
  let user
  if (customerIdentityCookieName in cookiesArray) {
    tokens = parseJson(cookiesArray[customerIdentityCookieName])
    user = jwtDecode(tokens.accessToken)
  } else if (cookiesArray.accessToken) {
    tokens = cookiesArray
    user = jwtDecode(tokens.accessToken)
  }
  if (user) {
    customerId = {
      id: user?.id,
      lastName: user?.lastName,
      firstName: user?.firstName,
      membership: user?.membership || {},
      email: user?.email,
      cashbackAmount: user?.cashbackTotal || '',
    }
  }

  return customerId
}
