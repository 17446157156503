import styles from './LogInAccount.module.scss'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'

type MyProps = {
  isOpen: any
  loginUser: any
}

const LogInAccount: FC<MyProps> = ({ isOpen, loginUser }) => {
  const { t }: { t: any } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        {!loginUser && t('already-have-an-account')}
      </p>
      <p className={`${styles.text} ${styles.button}`} onClick={isOpen}>
        {loginUser ? t('change') : t('log-in')}
      </p>
    </div>
  )
}

export default LogInAccount
