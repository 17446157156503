import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Image from 'next/image'
import { debounce } from 'lodash'
import styles from './ReviewItem.module.scss'
import QuantitySelector from '../QuantitySelector/QuantitySelector'
import { IReviewItem } from '../../../interfaces/ReviewItem'
import stylesButton from '../QuantitySelector/QuantitySelector.module.scss'
import { annualmembershipSku } from '../../../constants/constants'

const MemorizedReviewItem: FC<IReviewItem> = ({
  quantity,
  updateShopifyCart,
  removeItem,
  item,
  reconciliation,
}) => {
  const stateRef = useRef<any>()
  stateRef.current = reconciliation

  const [quantityToSend, setQuantityToSend] = useState<number>(quantity)
  const debouncedFunctionRef = useRef<any>()

  debouncedFunctionRef.current = () => {
    if (!quantityToSend) return
    updateShopifyCart('add', quantityToSend)
  }

  const debounceFunc = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), 500),
    []
  )

  const handleUpdate = () => {
    if (reconciliation && stateRef.current)
      return setTimeout(() => {
        handleUpdate()
      }, 100)
    return debounceFunc()
  }

  const handleChangeQuantity = (type: 'add' | 'remove') => {
    if (type === 'add') {
      setQuantityToSend((prev) => prev + 1)
      handleUpdate()
    } else {
      if (quantityToSend - 1 === 0) {
        removeItem()
      } else {
        handleUpdate()
      }
      setQuantityToSend((prev) => (prev > 1 ? prev - 1 : 0))
    }
  }

  useEffect(() => {
    if (!quantityToSend) setQuantityToSend(quantity)
  }, [quantity])

  return (
    <li className={styles.item}>
      <div className={styles.wrapper}>
        <Image
          src={
            item?.images?.[0]?.src ||
            item?.variant?.image?.src ||
            item?.image?.src ||
            '/'
          }
          alt={item?.title || ''}
          height={80}
          width={80}
        />

        {/* <img /> */}
        <p className={styles.title}>{item.title}</p>
      </div>
      <div className={`${styles.wrapper} ${styles.wrapperBlock}`}>
        <div className={styles.wrapperTop}>
          {item.sku !== annualmembershipSku &&
            item?.variant?.sku !== annualmembershipSku && (
              <div
                className={`${styles.addProduct} ${styles.addProductDesktop}`}
              >
                <QuantitySelector
                  subtractQuantity={() => handleChangeQuantity('remove')}
                  addQuantity={() => handleChangeQuantity('add')}
                  quantity={quantityToSend}
                  product_sku={item?.sku || item?.variant?.sku || ''}
                  productPrice={item?.price || item?.variant?.price || ''}
                  stylesBorder={stylesButton.quantitySelector}
                />
              </div>
            )}

          <p className={styles.cost}>
            $
            {(quantity * (item?.price || item?.variant?.price || 0)).toFixed(2)}
          </p>
        </div>
        <Image
          src="/images/delete.svg"
          alt="remove"
          height={24}
          width={24}
          onClick={removeItem}
          className={styles.remove}
        />
      </div>
    </li>
  )
}
const ReviewItem = memo(MemorizedReviewItem)
export default ReviewItem
