import { ProductDetails, CartContent } from '../interfaces/CartContent'

export const updateDataLayerHandler = (
  eventName: string,
  productsList: ProductDetails[] = [],
  cartContent: CartContent = {},
  grandTotal: number = 0
) => {
  const dataLayerArray = (window as any)?.dataLayer ?? null
  let cart: string = ''
  productsList.forEach((product: ProductDetails) => {
    let count = 0
    if (product?.frontVariantId) {
      if (cartContent[product?.frontVariantId]) {
        count = cartContent[product.frontVariantId]?.quantity
        cart += `${product.sku}:${count};`
      }
    }
  })

  const dataEvent: {
    event: string
    cart?: string
    total?: number
  } = { event: eventName }

  if (cart) {
    dataEvent.cart = cart
  }
  if (grandTotal) {
    dataEvent.total = grandTotal
  }

  if (dataLayerArray) {
    dataLayerArray.push(dataEvent)
  }
}
