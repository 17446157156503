import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import styles from './Breadcrumbs.module.scss'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

interface BreadcrumbsProps {
  nodes: {
    [index: string]: string | null | (() => void)
  }
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ nodes }) => {
    const { t }: { t: any } = useTranslation()
    const { width } = useWindowDimensions()

  return (
    <div className={styles.breadcrumbs}>
        {width < 1024 ?
            <Link href='/'>
                <a className={styles.buttonBack}>Back</a>
            </Link>
            : <ul>
                {Object.entries(nodes).map(([title, link]) => {
                    if (typeof link === 'function') {
                        return (
                            <li key={title}>
                <span onClick={() => link()}>
                  <span>{t(title)}</span>
                </span>
                            </li>
                        )
                    } else if (typeof link === 'string' || !link) {
                        return (
                            <li key={title}>
                                {link ? (
                                    <Link href={link}>
                                        <a>{t(title)}</a>
                                    </Link>
                                ) : (
                                    <span className={styles.noLink}>{t(title)}</span>
                                )}
                            </li>
                        )
                    }
                })}
            </ul>
        }
    </div>
  )
}

export default Breadcrumbs
