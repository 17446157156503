import styles from './ModalWindow.module.scss'
import { useEffect } from 'react'

const ModalWindow = ({ children, isOpened, closeFunction }) => {
  const closeOnEsc = (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      closeFunction()
    }
  }
  const closeOnOutterClick = (e) => {
    if (e.target.classList.contains(styles.allaModalBlock)) {
      closeFunction()
    }
  }

  useEffect(() => {
    if (isOpened) {
      window.addEventListener('keyup', closeOnEsc)
      window.addEventListener('click', closeOnOutterClick)
      document.documentElement.style.overflow = 'hidden'
    } else {
      window.removeEventListener('keyup', closeOnEsc)
      window.removeEventListener('click', closeOnOutterClick)
      document.documentElement.style.overflow = 'visible'
    }

    return () => {
      window.removeEventListener('keyup', closeOnEsc)
      window.removeEventListener('click', closeOnOutterClick)
      document.documentElement.style.overflow = 'visible'
    }
  })

  return (
    <div
      className={styles.allaModalBlock}
      style={{ display: isOpened ? 'block' : 'none' }}
    >
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            <img className="logo_club" src="/images/logo.svg" alt="Vitaliv" />
            <div className={styles.closeButton} onClick={closeFunction} />
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalWindow
