import { useState } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { getCookie } from 'cookies-next'
import * as Sentry from '@sentry/nextjs'

import {
  VITALIVE_SERVER_URL,
  shopifyCartCookie,
  // shopifyClient,
  clientType,
  customerIdentityCookieName,
  path,
  draftOrderIdCookie,
  localStatistic,
} from '../constants/constants'
import { SignInForm, ResetPassword } from '../interfaces/FormsInterfaces'
import { isSame } from '../utilities/compareValues'

import {
  UserInfo,
  CustomerOneClickSendingData,
} from '../interfaces/CustomerCheckout'
import { useRouter } from 'next/router'

import { refreshToken } from '../utilities/refreshTokens'
import { useNotifications } from './useNotifications'
import { ProductDetails } from '../interfaces/CartContent'
import { updateDataLayerHandler } from '../utilities/analyticsUtils'
import { createCheckout } from '../utilities/shopifyUtilites'
import { getObjectFromCookie } from '../utilities/getObjectFromCookie'

type SetOpenModal = (data: {
  open: boolean
  message: string
  isExist: boolean
}) => void

type Card = {
  number: string
  exp_month: number
  exp_year: number
  cvc: string
  cardHolder: {
    name: string
    email: string
    phoneNumber: string | null
  }
}

type Address = {
  firstName: string
  lastName: string
  middleName?: string
  phoneNumber: string | null
  province: string
  provinceCode: string
  zip: string
  city: string
  address1: string
  address2: string
  email: string
  country?: string
}

export const useSubmitForms = (
  productsList?: ProductDetails[],
  grandTotal?: number
) => {
  const router = useRouter()
  const [cookie, setCookie] = useCookies([
    shopifyCartCookie,
    customerIdentityCookieName,
  ])
  const [isFetching, setIsFetching] = useState(false)

  let draftOrderID = getCookie(draftOrderIdCookie) as any
  const { addError } = useNotifications()
  let statistic: string | undefined
  if (typeof window !== 'undefined') {
    statistic = window.localStorage.getItem(localStatistic) || undefined
  }

  const getError = (err: any) => {
    let stringError = ''
    if (err?.response?.data?.details) {
      for (const [key, value] of Object.entries(err.response.data?.details)) {
        stringError = `${stringError} ${key}: ${value};`
      }
      return addError(stringError)
    } else if (err?.response?.data?.message) {
      return addError(err?.response?.data?.message)
    } else {
      return addError('Something wrong, try some later')
    }
  }

  const checkAddressChanged = (
    _data: { [key: string]: string },
    _userInfo: UserInfo
  ) => {
    let _isChanged = false

    Object.keys(_data).forEach((dataElement: string) => {
      if (!isSame(_data[dataElement], _userInfo[dataElement])) {
        _isChanged = true
      }
    })

    return _isChanged
  }

  const objectCollector = (
    _data: { [key: string]: string },
    objectToCollect: any,
    type: string
  ) => {
    switch (type) {
      case 'card': {
        Object.keys(_data).map((key: string) => {
          if (key === 'card_expirationDate') {
            const splitedDate = _data[key].split('/')
            objectToCollect.exp_month = +splitedDate[0]
            objectToCollect.exp_year = +splitedDate[1]
          } else if (key === 'card_cardHolder') {
            objectToCollect.cardHolder.name = _data[key]
            objectToCollect.cardHolder.email = _data.email?.trim()
            objectToCollect.cardHolder.phoneNumber = _data.phoneNumber?.trim()
          } else {
            objectToCollect[key.replace('card_', '')] = _data[key]
          }
        })
        break
      }
      case 'address': {
        Object.keys(_data).map((key: string) => {
          if (key === 'firstName') {
            let userName = _data[key].split(' ')
            if (userName.length > 1) {
              objectToCollect.firstName = userName[0]
              objectToCollect.middleName = userName[1]
            } else objectToCollect.firstName = _data[key]
          } else if (key === 'country') {
            objectToCollect[key] = 'US'
          } else {
            objectToCollect[key] = _data[key]?.trim()
          }
        })
        break
      }
      default:
        return
    }
  }

  const submitCheckoutForAllForms = async (
    data: { [key: string]: string },
    shippingSameAsBilling: boolean,
    setOpenPopup: SetOpenModal,
    paymentProvider: string,
    customerId?: string,
    discountCode: string | null = null
  ) => {
    setIsFetching(true)
    let card: Card = {
      number: '',
      exp_month: 0,
      exp_year: 0,
      cvc: '',
      cardHolder: {
        name: '',
        email: '',
        phoneNumber: null,
      },
    }
    let customerShipping: Address = {
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: null,
      province: '',
      provinceCode: '',
      zip: '',
      city: '',
      address1: '',
      address2: '',
      email: '',
      country: 'United States',
    }

    let customerBilling: Address = {
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: null,
      province: '',
      provinceCode: '',
      zip: '',
      city: '',
      address1: '',
      address2: '',
      email: '',
      country: 'United States',
    }

    const _shopifyCartCookie = getCookie(shopifyCartCookie)
    const _shopifyCookie = _shopifyCartCookie
      ? getObjectFromCookie(_shopifyCartCookie as string)
      : _shopifyCartCookie

    let line_items: { variant_id: number; quantity: number }[] = []

    Object.keys(data).map((key: string) => {
      if (key.includes('card_')) {
        if (key === 'card_expirationDate') {
          const splitedDate = data[key].split('/')
          card.exp_month = +splitedDate[0]
          card.exp_year = +splitedDate[1]
        } else if (key === 'card_cardHolder') {
          card.cardHolder.name = data[key]?.trim()
          card.cardHolder.email = data.email?.trim()
          card.cardHolder.phoneNumber = data.phoneNumber?.trim()
        } else {
          card[key.replace('card_', '')] = data[key]
        }
      } else if (key.includes('billing_')) {
        if (key === 'firstName') {
          let userName = data[key].split(' ')
          if (userName.length > 1) {
            customerBilling.firstName = userName[0]
            customerBilling.middleName = userName[1]
          } else customerBilling.firstName = data[key]
        } else if (key === 'billing_country') {
          customerBilling[key.replace('billing_', '')] = 'US'
        } else {
          customerBilling[key.replace('billing_', '')] = data[key]?.trim()
        }
      } else {
        if (key === 'firstName') {
          let userName = data[key].split(' ')
          if (userName.length > 1) {
            customerShipping.firstName = userName[0]
            customerShipping.middleName = userName[1]
          } else customerShipping.firstName = data[key]
        } else if (key === 'country') {
          customerShipping[key] = 'US'
        } else {
          customerShipping[key] = data[key]?.trim()
        }
      }
    })

    Object.keys(_shopifyCookie.checkoutLineItems).forEach((key: string) => {
      if (key && _shopifyCookie.checkoutLineItems[key]) {
        line_items.push({
          variant_id: +_shopifyCookie.checkoutLineItems[key].variantId,
          quantity: _shopifyCookie.checkoutLineItems[key].quantity,
        })
      }
    })

    let _dataToSend =
      paymentProvider === 'paypal'
        ? {
            draftOrderID,
            line_items,
            customerShipping,
            billingSameAsShipping: shippingSameAsBilling,
            paymentProvider,
            customerId: customerId ? +customerId : undefined,
            statistic,
            // customerBilling: !shippingSameAsBilling ? customerBilling : null,
          }
        : {
            draftOrderID,
            card,
            line_items,
            customerShipping,
            billingSameAsShipping: shippingSameAsBilling,
            customerBilling: !shippingSameAsBilling ? customerBilling : null,
            paymentProvider,
            customerId: customerId ? +customerId : undefined,
            statistic,
          }

    if (discountCode) {
      _dataToSend['discountCode'] = discountCode
    }

    updateDataLayerHandler(
      'purchase',
      productsList!,
      cookie.shopifyCart.checkoutLineItems,
      grandTotal!
    )

    try {
      const res = await axios.post(
        `${VITALIVE_SERVER_URL}/checkout`,
        _dataToSend
      )

      if (paymentProvider === 'paypal') {
        window.open(res.data.url, '_self')
      } else {
        router.push(
          `/cart/thankyou/?order_id=${res.data.shopifyOrder.body.draft_order.order_id}`
        )
      }
    } catch (err) {
      Sentry.captureException(err)
      console.log('submitCheckout: ', err)
      if ([402, 405, 422].includes(err.response.status)) {
        getError(err)
      }
      addError('Something wrong, try some later')
    } finally {
      setIsFetching(false)
    }
  }

  const submitCheckoutOneClick = async (
    userInfo: UserInfo,
    discount: number,
    shippingSameAsBilling: boolean,
    setOpenPopup: SetOpenModal,
    data: { [key: string]: string },
    paypal: boolean,
    discountCode: string | null = null
  ) => {
    setIsFetching(true)
    try {
      const _address = {}
      const _card = {}
      const _billingAddress = {}

      const _identity = getCookie(customerIdentityCookieName)
      const _accessToken = _identity
        ? getObjectFromCookie(_identity as string).accessToken
        : _identity

      const _shopifyCartCookie = getCookie(shopifyCartCookie)
      const _shopifyCookie = _shopifyCartCookie
        ? getObjectFromCookie(_shopifyCartCookie as string)
        : _shopifyCartCookie

      Object.keys(data).map((el) => {
        if (el.includes('card_')) {
          _card[el] = data[el]
        } else if (el.includes('billing_')) {
          if (el === 'billing_country') {
            _billingAddress[el.replace('billing_', '')] = 'United States'
          } else {
            _billingAddress[el.replace('billing_', '')] = data[el]
          }
        } else {
          if (el === 'country') {
            _address[el] = 'United States'
          } else {
            _address[el] = data[el]
          }
        }
      })

      const _isAddressChanged = checkAddressChanged(_address, userInfo)
      const _isCardChanged = checkAddressChanged(_card, userInfo)
      let card: Card = {
        number: '',
        exp_month: 0,
        exp_year: 0,
        cvc: '',
        cardHolder: {
          name: '',
          email: '',
          phoneNumber: null,
        },
      }
      let customerShipping: Address = {
        firstName: '',
        lastName: '',
        middleName: '',
        phoneNumber: null,
        province: '',
        provinceCode: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
        email: '',
        country: 'United States',
      }

      let customerBilling: Address = {
        firstName: '',
        lastName: '',
        middleName: '',
        phoneNumber: null,
        province: '',
        provinceCode: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
        email: '',
        country: 'United States',
      }

      const line_items: {
        variant_id: number
        quantity: number
      }[] = []

      Object.keys(_shopifyCookie.checkoutLineItems).forEach((lineItem) => {
        if (lineItem && _shopifyCookie.checkoutLineItems[lineItem]) {
          line_items.push({
            variant_id: +_shopifyCookie.checkoutLineItems[lineItem].variantId,
            quantity: +_shopifyCookie.checkoutLineItems[lineItem].quantity,
          })
        }
      })

      const _sendingData: CustomerOneClickSendingData = {
        draftOrderID,
        customerId: userInfo.customerId,
        line_items,
        paypal,
        cashbackAmount: discount,
        billingSameAsShipping: shippingSameAsBilling,
        statistic,
      }

      if (_isAddressChanged) {
        objectCollector(_address, customerShipping, 'address')
        _sendingData.customerShipping = customerShipping
      }
      if (_isCardChanged) {
        objectCollector(_card, card, 'card')
        _sendingData.card = card
      }

      if (!shippingSameAsBilling) {
        objectCollector(_billingAddress, customerBilling, 'address')
        _sendingData.customerBilling = customerBilling
      }
      updateDataLayerHandler(
        'purchase',
        productsList!,
        _shopifyCookie.checkoutLineItems,
        grandTotal!
      )

      if (discountCode) {
        _sendingData['discountCode'] = discountCode
      }

      const res = await axios.post(
        `${VITALIVE_SERVER_URL}/checkout/oneclick`,
        _sendingData,
        {
          headers: {
            Authorization: `Bearer ${_accessToken}`,
          },
        }
      )

      if (paypal) {
        window.open(res.data.url, '_self')
      } else {
        router.push(
          `/cart/thankyou/?order_id=${res.data.shopifyOrder.body.draft_order.order_id}`
        )
      }
    } catch (err: any) {
      Sentry.captureException(err)
      if (err?.response?.status === 401) {
        refreshToken(() =>
          submitCheckoutOneClick(
            userInfo,
            discount,
            shippingSameAsBilling,
            setOpenPopup,
            data,
            paypal
          )
        )
      } else {
        getError(err)
        addError('Something wrong, try some later')
      }
    } finally {
      setIsFetching(false)
    }
  }

  const signInSubmit: SignInForm = async (formData, setError, setFulfilled) => {
    try {
      const res = await axios.post(`${VITALIVE_SERVER_URL}/auth/signin`, {
        ...formData,
        clientType,
      })
      setCookie(customerIdentityCookieName, res.data, path)
      createCheckout(formData.login!)

      if (setFulfilled) {
        setFulfilled(true)
      } else {
        router.push('/account')
      }
    } catch (err: any) {
      Sentry.captureException(err)
      const data = err?.response
        ? err.response?.data
        : { message: 'Something wrong, try some later' }
      if (setError) {
        setError({ ['wrongCredentialsMessage']: data.message })
      } else {
        addError(data.message)
      }
      setFulfilled && setFulfilled(false)
    }
  }

  const forgotPassword: SignInForm = async (
    formData,
    setError,
    setFulfilled
  ) => {
    try {
      await axios.post(`${VITALIVE_SERVER_URL}/auth/resetPassword`, {
        ...formData,
      })
      setFulfilled && setFulfilled(true)
    } catch (err: any) {
      Sentry.captureException(err)
      const { data } = err.response
      console.log('Error: ', data)
      setFulfilled && setFulfilled(false)
    }
  }

  const resetPassword = async (
    formData: ResetPassword,
    setError: any,
    setFulfilled: (isFullFieled: boolean) => void
  ) => {
    try {
      await axios.post(`${VITALIVE_SERVER_URL}/auth/restorePassword`, {
        ...formData,
      })
      setFulfilled(true)
    } catch (err: any) {
      Sentry.captureException(err)
      const { data } = err.response
      setError(() => ({
        commonErrorMessage: data.message,
      }))
      setFulfilled(false)
    }
  }

  return {
    submitCheckoutForAllForms,
    signInSubmit,
    forgotPassword,
    resetPassword,
    submitCheckoutOneClick,
    isFetching,
  }
}
