import React, { FC, useState } from 'react'
import Image from 'next/image'
import styles from './GoalFilterELement.module.scss'

type MyProps = {
  imgName: string
  title: string
  filterName: string
  el: any
}

const GoalFilterELement: FC<MyProps> = ({ imgName, title, filterName, el }) => {
  const [isImgLoaded, setIsImgLoaded] = useState(true)
  return (
    <>
      {isImgLoaded && (
        <Image
          src={`/images/FilterIcons/GoalsIcons/${imgName}.svg`}
          alt={imgName}
          width={24}
          height={24}
          objectFit="contain"
          onError={() => setIsImgLoaded(false)}
        />
      )}
      <p
        className={`${!isImgLoaded ? styles.sectionsText : ''}
      ${filterName === el.handle ? styles.sectionsItemActive : ''}`}
      >
        {title}
      </p>
    </>
  )
}

export default GoalFilterELement
