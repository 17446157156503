import React, { FC } from 'react'
import Image from 'next/image'
import styles from './ChipButton.module.scss'

type MyProps = {
  isChosen: boolean
  action?: (el: any) => void
  title: string
  valueToChose?: string | number
  imgSrc?: string
  imgAlt?: string
  isRemoveButton?: boolean
}

const ChipButton: FC<MyProps> = ({
  isChosen,
  action,
  title,
  valueToChose,
  imgSrc,
  imgAlt,
  isRemoveButton,
}) => {
  const actionHandler = () => {
    action && action(valueToChose)
  }
  return (
    <div
      className={`${styles.filterItem}  ${isChosen ? styles.filterActive : ''}`}
      onClick={actionHandler}
    >
      {imgSrc && (
        <span className={styles.filterImg}>
          <Image
            src={imgSrc}
            alt={imgAlt}
            width={24}
            height={24}
            objectFit="contain"
          />
        </span>
      )}
      <p className={`${styles.filterText}`}>{title}</p>
    </div>
  )
}

export default ChipButton
