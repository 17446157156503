import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isCreditCard from 'validator/lib/isCreditCard'
import moment from 'moment'

const checkDate = (inputedDate) => {
  const date = new Date()
  const month = date.getMonth() + 1
  const year = date.getFullYear() - 2000
  let valueMonth = Number(inputedDate.substr(0, 2))
  let valueYear = Number(inputedDate.substr(3))

  if (valueMonth > 12) {
    return 'Incorrect Input'
  }

  if (valueYear > year || (valueYear === year && valueMonth > month)) {
    return true
  } else {
    return `Expiration date should be later than ${moment().format('MM/YY')}`
  }
}

const checkBetweenOflength = (startLength, endLength, value) => {
  if (value.length > startLength && value.length < endLength) {
    return 'phone-number-should-be-at-least-7-characters-long'
  }
  return true
}

export const ContactInformation = [
  {
    fieldType: 'inputField',
    inputName: 'email',
    inputType: 'text',
    inputPlaceholder: 'email',
    labelName: 'email',
    asyncValidate: true,
    validate: {
      required: 'field-is-required',
      validate: (value) =>
        isEmail(value.trim()) || 'please-fill-field-with-valid-email',
    },
    style: null,
    style3: 'inputInvalid',
    style4: 'textEmail',
  },
]

export const signInFormData1 = [
  {
    fieldType: 'inputField',
    inputName: 'login',
    inputType: 'email',
    inputPlaceholder: 'email',
    labelName: 'email',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      validate: (value) =>
        isEmail(value.trim()) || 'please-fill-field-with-valid-email',
    },
    style: 'input',
    style3: 'inputInvalid',
    style4: 'textEmail',
  },
  {
    fieldType: 'inputField',
    inputName: 'password',
    inputType: 'password',
    inputPlaceholder: 'password',
    labelName: 'password',
    asyncValidate: false,
    validate: {
      required: 'field-is-required',
      minLength: {
        value: 5,
        message: 'passwords-length-should',
      },
    },
    style: 'input',
    style3: 'inputInvalid',
  },
]

export const AddressAndUserFormModel = (isBilling) => [
  [
    {
      fieldType: 'inputField',
      inputName: isBilling ? 'billing_firstName' : 'firstName',
      inputType: 'text',
      inputPlaceholder: 'first-name',
      labelName: 'first-name',
      asyncValidate: false,
      validate: { required: 'first-name-is-required' },
      style: 'inputName',
      style3: 'inputInvalid',
    },
    {
      fieldType: 'inputField',
      inputName: isBilling ? 'billing_lastName' : 'lastName',
      inputType: 'text',
      inputPlaceholder: 'last-name',
      labelName: 'last-name',
      asyncValidate: false,
      validate: { required: 'last-name-is-required' },
      style: 'inputName',
      style3: 'inputInvalid',
    },
  ],
  {
    fieldType: 'inputField',
    inputName: isBilling ? 'billing_address1' : 'address1',
    inputType: 'text',
    inputPlaceholder: 'Address',
    labelName: 'address',
    list: 'autoCompleteAddress',
    asyncValidate: false,
    validate: {
      required: 'address-is-required',
      minLength: {
        value: 3,
        message: 'address-should-be-at-least-3-characters-long',
      },
    },
    style: '',
    style3: 'inputInvalid',
  },
  {
    fieldType: 'inputField',
    inputName: isBilling ? 'billing_address2' : 'address2',
    inputType: 'text',
    inputPlaceholder: 'apartment-suite-etc-optional',
    labelName: 'apartment-suite-etc-optional',
    asyncValidate: false,
    style: '',
    style3: 'inputInvalid',
    style4: 'inputTop',
  },
  {
    fieldType: 'inputField',
    inputName: isBilling ? 'billing_city' : 'city',
    inputType: 'text',
    asyncValidate: false,
    inputPlaceholder: 'City',
    labelName: 'city',
    validate: { required: 'city-is-required' },
    style: 'inputTop',
    style3: 'inputInvalid',
    style4: 'inputTop',
  },
  [
    // {
    //   fieldType: 'selectField',
    //   inputName: isBilling ? 'billing_country' : 'country',
    //   inputType: 'select',
    //   asyncValidate: false,
    //   disabled: true,
    //   inputPlaceholder: 'country-region',
    //   labelName: 'country-region',
    //   style: 'select',
    // },
    {
      fieldType: 'selectField',
      inputName: isBilling ? 'billing_provinceCode' : 'provinceCode',
      inputType: 'select',
      asyncValidate: false,
      inputPlaceholder: 'State*',
      labelName: 'state',
      validate: { required: 'province-is-required' },
      style: 'select',
      style3: 'inputInvalid',
    },
    {
      fieldType: 'inputField',
      inputName: isBilling ? 'billing_zip' : 'zip',
      inputType: 'text',
      inputPlaceholder: 'postal-code',
      labelName: 'postal-code',
      asyncValidate: false,
      validate: {
        required: 'postal-code-is-required',
        minLength: {
          value: 5,
          message: 'postal-code-should-be-at-least-5-characters-long',
        },
      },
      style: null,
      style3: 'inputInvalid',
    },
  ],
  {
    fieldType: 'inputMask',
    maskChar: null,
    formatChars: { 0: '[0-9?]', 9: '[0-9]' },
    inputName: isBilling ? 'billing_phoneNumber' : 'phoneNumber',
    inputType: 'tel',
    inputPlaceholder: 'phone-optional',
    labelName: 'phone-optional',
    asyncValidate: true,
    validate: {
      validate: (value) =>
        value === '+1' ||
        checkBetweenOflength(2, 7, value) ||
        isMobilePhone(value) ||
        'incorrect-phone-number',
      pattern: {
        value: /^[0-9+\-_ ()]*$/,
        message: 'incorrect-phone-number',
      },
    },
    style: '',
    style2: 'inputPhone',
    style3: 'inputInvalid',
  },
]

const checkCCValidation = (
  inputName,
  value,
  callBackChecker,
  errorMessage,
  userInfo
) => {
  if (userInfo) {
    if (
      value === userInfo[inputName] ||
      `**** **** **** ${value}` === userInfo[inputName]
    ) {
      return true
    } else if (callBackChecker) {
      return callBackChecker(value) || errorMessage
    } else if (inputName === 'card_cvc') {
      if (value.length < 3) {
        return errorMessage
      }
      return /^\d+$/.test(value) ? true : errorMessage
    }
    return errorMessage
  }

  if (callBackChecker) {
    return callBackChecker(value) || errorMessage
  }
  return errorMessage
}

export const CreditCardFormModel = (userInfo) => [
  {
    fieldType: 'inputMask',
    maskChar: null,
    formatChars: { 0: '[0-9?]', 9: '[0-9]' },
    inputName: 'card_number',
    inputType: 'text',
    inputPlaceholder: 'card-number',
    labelName: 'card-number',
    asyncValidate: true,
    validate: {
      required: 'card-number-is-required',
      validate: (value) =>
        checkCCValidation(
          'card_number',
          value,
          isCreditCard,
          'incorrect-card-number',
          userInfo
        ),
    },
    style: '',
    style2: 'inputLock',
    style3: 'inputInvalid',
  },
  {
    fieldType: 'inputField',
    inputName: 'card_cardHolder',
    inputType: 'text',
    inputPlaceholder: 'Card Holder*',
    labelName: 'Card Holder',
    asyncValidate: true,
    validate: {
      required: 'name-on-card-is-required',
    },
    style: 'inputTop',
    style3: 'inputInvalid',
    style4: 'inputTop',
  },
  [
    {
      fieldType: 'inputMask',
      maskChar: null,
      formatChars: { 0: '[0-1?]', 9: '[0-9]' },
      inputName: 'card_expirationDate',
      inputType: 'text',
      inputPlaceholder: 'expiration-date-mm-yy',
      labelName: 'expiration-date-mm-yy',
      style: '',
      asyncValidate: true,
      validate: {
        required: 'this-field-is-required',
        maxLength: {
          value: 5,
          message: 'incorrect-input',
        },
        validate: (value) =>
          userInfo
            ? checkCCValidation(
                'card_expirationDate',
                value,
                checkDate,
                '',
                userInfo
              )
            : checkDate(value),
      },
      style3: 'inputInvalid',
    },
    {
      fieldType: 'inputMask',
      maskChar: null,
      formatChars: { 0: '[0-9?]', 9: '[0-9]' },
      inputName: 'card_cvc',
      inputType: 'text',
      inputPlaceholder: 'CVC/CVV*',
      labelName: 'CVC/CVV',
      validate: {
        required: 'this-field-is-required',
        minLength: {
          value: 3,
          message: 'incorrect-input',
        },
        validate: userInfo
          ? (value) =>
              checkCCValidation(
                'card_cvc',
                value,
                null,
                'incorrect-input',
                userInfo
              )
          : null,
      },
      style: '',
      style2: 'inputPhone',
      style3: 'inputInvalid',
      asyncValidate: true,
    },
  ],
]

export const BreadCrumbEnum = {
  0: 'Review Order',
  1: 'Delivery',
  2: 'Payment',
}

export const optionalFields = {
  phoneNumber: true,
  address2: true,
  country: true,
  billing_phoneNumber: true,
  billing_address2: true,
  billing_country: true,
}

export const addressFields = {
  address1: true,
  zip: true,
  city: true,
  provinceCode: true,
}
