import React, { FC } from 'react'
import styles from './Preloader.module.scss'

type PreloaderProps = {
  variant?: 'full' | 'part'
}

const Preloader: FC<PreloaderProps> = ({
  variant = 'full',
}: PreloaderProps) => {
  return (
    <div
      className={`${variant === 'full' && styles.preloaderWrapperFull} ${
        variant === 'part' && styles.preloaderWrapperPart
      }`}
    >
      <div className={styles.loader}></div>
    </div>
  )
}

export default Preloader
